* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, html {
  height: 100%;
  font-family: 'Arial', sans-serif;
  background-color: rgb(0, 0, 0); 
}

.App {
  position: relative;
  width: 100%;
  min-height: 100vh; 
}

.App-logo {
  height: 35px; 
}

.Tree-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 29vw;
  max-height: 80vh; 
  height: auto;
  z-index: 0; 
}

@media (max-width: 1024px) {
  .Tree-image {
    object-position: left;
    width: 60vw;
    max-height: 70vh; 
  }
}

@media (max-width: 767px) {
  .Tree-image {
    object-position: left;
    width: 60vw;
    max-height: 60vh;
  }
}

.App-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0; 
  transition: opacity 7s; 
  z-index: 1;
  pointer-events: none; 
}

.App-background.show {
  opacity: 0.9; 
}

.App-background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('/public/wback.png'); /* Default desktop image */
  background-size: cover;
  background-position: center;
}

/* For iPhone or smaller devices */
@media (max-width: 767px) {
  .App-background::before {
    background-image: url('/public/wbackiphone.png?v=1'); /* Mobile/iPhone image */
    background-size: cover;
    background-position: center;
  }
}

.App-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3; 
}

.App-contact-text {
  color: rgb(255, 248, 248);
  font-weight: bold;
  font-family: 'Arial', sans-serif;
  font-size: 18px;
  text-decoration: none;
  cursor: pointer;
  position: relative;
}

.contact-container {
  position: relative;
}

.contact-info {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: black;
  padding: 15px 20px;
  z-index: 4;
  font-size: 15px;
  border: 1px solid rgb(255, 250, 250);
  margin-top: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(237, 227, 227, 0.1);
  backdrop-filter: blur(5px);
}

.contact-info p {
  margin: 0;
  color: #ffe3e3;
  font-weight: bold;
}

.App-contact-text:hover {
  text-decoration: underline;
}

.App-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 5; 
  color: rgb(255, 255, 255);
  font-family: 'Arial', sans-serif;
  font-size: px;
  padding: 10px;
}

@media (max-width: 1024px) {
  .App-logo {
    height: 45px;
  }

  .App-contact-text {
    font-size: 22px;
  }
}

@media (max-width: 767px) {
  .App-logo {
    height: 35px; 
  }

  .App-contact-text {
    font-size: 18px; 
  }

  .App-header {
    padding: 15px; 
  }
}

@media (max-width: 480px) {
  .App-logo {
    height: 30px; 
  }

  .App-contact-text {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .App-footer {
    font-size: 10px; 
  }
}
